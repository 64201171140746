<template>
  <div>
    <router-view v-show='this.$route.matched.length==3'></router-view>
    <PageHeaderLayout v-show='this.$route.matched.length==2'>
      <div class='main-page-content'>
        <div style='width: 100%;display: flex;flex-direction: row-reverse'>
          <div>
            <el-radio-group v-model='showVersion'>
              <el-radio-button label='old'>旧版</el-radio-button>
              <el-radio-button label='new'>新版</el-radio-button>
            </el-radio-group>
          </div>

        </div>
        <DepartmentList v-if='showVersion==="old"' />
        <DeptTreeTable v-else/>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import DepartmentList from '@/pages/departments/DepartmentsList'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import DeptTreeTable from '@/pages/departments/DeptTreeTable'

export default {
  name: 'Index',
  components: { DeptTreeTable, DepartmentList, PageHeaderLayout },
  data() {
    return {
      showVersion: 'old'
    }
  }
}
</script>

<style scoped>

</style>