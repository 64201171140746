<template>
  <div>
    <div>
        <el-row class="table-header">
          <el-col :span="12">
            <div style="display: inline">
              <el-tooltip effect="dark" content="刷新" placement="top-start"
                          v-if="buttonType=='icon'">
                <el-button type="primary" size="medium" icon="el-icon-refresh"
                           @click="syncDpt(0)" :loading="loadingDpt" :disabled="loadingDpt"></el-button>
              </el-tooltip>
              <el-button type="primary" size="medium" icon="iconfont "
                         v-if="buttonType=='text'"
                         @click="syncDpt(0)" :loading="loadingDpt" :disabled="loadingDpt">刷新
              </el-button>
            </div>
            &nbsp;
            <b>最近更新时间：</b> <span>{{ last_sync_time }}</span>
          </el-col>
<!--          <el-col :span="12" style="text-align: right">-->
<!--            <el-tooltip content="先清空已同步的部门和用户信息，再拉取钉钉上最新的组织架构数据。" placement="bottom" effect="light"-->
<!--                        v-if="userPermissions.indexOf('departments_sync') != -1">-->
<!--              <el-link type="warning" @click="syncDpt(1)" :loading="loadingDpt" :disabled="loadingDpt">全量更新<i-->
<!--                  class="el-icon-refresh-left"></i>-->
<!--              </el-link>-->
<!--            </el-tooltip>-->
<!--          </el-col>-->
        </el-row>
        <TreeTable :data="departmentsList" :columns="columns" :loading="loadingStaus" ref="treeTable"
                   highlight-current-row>
          <el-table-column
              slot="first-column"
              width="160"
              align="center"
              label="Drag">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="拖动排序" placement="top-start">
                <span class="drag-handle" :data-id="scope.row.id" :data-parent_id="scope.row.parent_id" :data-depth="scope.row.depth"><i class="el-icon-rank"></i></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='icon'"
              label="操作" width="120" align="left" header-align="center">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="成员管理" placement="top-start"
                          v-if="userPermissions.indexOf('roles_users') != -1">
                <el-button size="mini" icon="iconfont icon-chengyuanguanli4"
                           @click="manageMembers(scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              label="操作">
            <template slot-scope="scope">
              <el-button size="mini" v-if="userPermissions.indexOf('roles_users') != -1"
                         @click="manageMembers(scope.row)">成员管理
              </el-button>
            </template>
          </el-table-column>
        </TreeTable>
      </div>
  </div>
</template>

<script>
import TreeTable from '@/components/TreeTable'
import ModalDialog from '@/components/ModalDialog'
import Sortable from 'sortablejs'
import { mapGetters } from 'vuex'

export default {
  name: "DepartmentList",
  components: {
    TreeTable,
    ModalDialog
  },
  data() {
    return {
      loadingDpt: false,
      loadingStaus: true,
      last_sync_time: '',
      dialogData: {
        visible: false,
        title: '',
        width: '50%',
        loading: true,
        modal: false
      },
      columns: [
        // {
        //   title: 'ID',
        //   value: 'id',
        //   width: 200
        // },
        // {
        //   title: '部门标识',
        //   value: 'dpt_id',
        //   width: 200
        // },
        {
          title: '部门名称',
          value: 'name'
        },
        {
          title: '成员数',
          value: 'dpt_users_count',
          header_align: 'center',
          align: 'center',
          width: 200
        }
      ],
      // 表格列表数据
      departmentsList: [],
      // 表单结构
      formData: {
        display_name: ''
      },
      // 表单验证
      rules: {
        display_name: [{ required: true, message: '输入权限名称', trigger: 'blur' }],
        name: [{ required: true, message: '输入权限标识', trigger: 'blur' }],
      },
      // 定义权限标识输入方式，是否继成
      // 在添加修改子权限时候生效
      isInherit: true,
      namePrefix: '',
      handleChild: false,
      // 定义表格拖拽排序
      sortable: null
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    async syncDpt(type) {
      this.loadingDpt = true;
      //同步钉钉部门
      if (type === 1) {
        this.$confirm('此操作将清空部门和用户信息再重新同步, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.syncInfos()
          setTimeout(() => {
            this.loadingDpt = false;
          }, 5000)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
          this.loadingDpt = false;
        });

      } else {
        await this.getDepartmentsList()
        await this.lastSyncTime()
        this.loadingDpt = false;
      }
    },
    async syncInfos() {
      await this.$api.syncDpt()
      this.$message.success('已开始同步钉钉部门和用户信息，请稍后查阅')
      // await this.getDepartmentsList()
      // await this.lastSyncTime()
    },
    // 管理成员
    manageMembers(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/manage-members')
    },
    async getDepartmentsList() {
      this.departmentsList = [];
      let { list } = await this.$api.getDepartmentsList()
      this.$nextTick(() => {
        this.departmentsList = list
      })
    },
    // 切换权限标识的输入方式，继成父级、自定义
    toggleInput() {
      this.isInherit = !this.isInherit
    },
    // 拖拽排序数据提交，请求接口
    async dragSortSubmit(orderIds) {
      // let ids = this.$refs['treeTable'].getExpandIds()
      let info = await this.$api.orderDepartment(orderIds)
      if (info == 'ok') {
        // let { list } = await this.$api.getDepartmentsList()
        // this.departmentsList = []
        // this.$nextTick(() => {
        //   this.departmentsList = list
        //   this.$nextTick(() => {
        //     this.$refs['treeTable'].initTableExpand(ids)
        //   })
        // })

      }
      this.$message.success('保存成功!')
    },
    // 表格拖拽排序，同层级移动有效果
    dragSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        handle: ".drag-handle",
        setData: function (dataTransfer) {
          dataTransfer.setData('Text', '')
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        },
        onEnd: evt => {
          let oldId = evt.item.querySelectorAll('.drag-handle')[0].dataset.id
          let Ids = []
          let orderIds = []
          let newList = []
          let tmp = el.querySelectorAll('.drag-handle')
          for (let i = 0, len = tmp.length; i < len; i++) {
            newList[tmp[i].dataset.id] = { 'parent_id': tmp[i].dataset.parent_id, 'depth': tmp[i].dataset.depth }
            Ids.push(tmp[i].dataset.id)
          }
          // 处理组合实际请求后台数据
          for (let i = 0, len = Ids.length; i < len; i++) {
            if (newList[oldId].parent_id == newList[Ids[i]].parent_id && newList[oldId].depth == newList[Ids[i]].depth) {
              orderIds.push(Ids[i])
            }
          }
          this.dragSortSubmit(orderIds)
        },
      })
    },
    async lastSyncTime() {
      let { last_sync_time } = await this.$api.lastSyncTime()
      this.last_sync_time = last_sync_time;
    }
  },
  mounted() {
    this.lastSyncTime()
    this.getDepartmentsList()
    this.loadingStaus = false
    this.$nextTick(() => {
      this.dragSort()
    })
  },
}
</script>

<style lang="stylus">
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff
</style>
