<template>
<div>
  <el-form :inline='true'>
    <el-form-item label='部门名称'>
      <el-input
        v-model='queryParams.name'
        placeholder='请输入部门名称'
        clearable
        size='small'
        @keyup.enter.native='handleQuery'
      />
    </el-form-item>
    <el-form-item>
      <el-button
        class='filter-item'
        type='primary'
        icon='el-icon-search'
        size='mini'
        @click='handleQuery'
      >搜索
      </el-button>
    </el-form-item>
  </el-form>

  <el-table
    v-loading='loading'
    :data='deptList'
    row-key='id'
    default-expand-all
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
  >
    <el-table-column prop='name' label='部门名称' width='300'></el-table-column>
    <el-table-column prop='dpt_id' label='部门ID' width='200'></el-table-column>
    <el-table-column prop='parent_id' label='父部门ID' width='200'></el-table-column>
    <el-table-column prop='order' label='排序' width='100'></el-table-column>
    <!--          <el-table-column prop='status' label='状态' width='100'></el-table-column>-->
    <el-table-column label='创建时间' align='center' prop='created_at' width='200' />
    <el-table-column label='更新时间' align='center' prop='updated_at' width='200' />
    <el-table-column label='操作' align='center' class-name='small-padding fixed-width'>
      <template slot-scope='scope'>
        <el-tooltip effect='dark' content='成员管理' placement='top-start'
                    v-if="userPermissions.indexOf('roles_users') != -1">
          <el-button size='mini' icon='iconfont icon-chengyuanguanli4'
                     @click='manageMembers(scope.row)'></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeptTreeTable',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      showVersion: 'old',
      // 遮罩层
      loading: false,
      // 表格树数据
      deptList: [],
      // 部门树选项
      deptOptions: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [{ key: 'Y', value: '正常' }, { key: 'N', value: '停用' }],
      // 查询参数
      queryParams: {
        name: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        parent_id: [
          { required: true, message: '上级部门不能为空', trigger: 'blur' }
        ],
        dept_name: [
          { required: true, message: '部门名称不能为空', trigger: 'blur' }
        ],
        order_num: [
          { required: true, message: '菜单顺序不能为空', trigger: 'blur' }
        ],
        email: [
          {
            required: false,
            type: 'email',
            message: '\'请输入正确的邮箱地址',
            trigger: ['blur', 'change']

          }
        ],
        phone: [
          {
            required: false,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    /** 查询部门列表 */
    async getList() {
      this.loading = true
      let { list } = await this.$api.getDingDeptList(this.queryParams)
      this.deptList = await this.handleTree(list, 'dpt_id', 'parent_id')
      this.loading = false
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList()
    },
    // 管理成员
    manageMembers(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/manage-members')
    }

  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>